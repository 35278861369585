import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './LandingWrapper.module.less';
import Header from '../Header';
import AuthForm from '../AuthForm';
import PrivacyPolicy from '../PrivacyPolicy';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../redux/auth';
import FetchApi from '../../helpers';
import Footer from '../Footer';
import useClientHeight from '../../hook/useClientHeight';
import { useNavigate } from 'react-router-dom';
import { Table } from '../DashboardTable/Table/Table';
import { ApiLocalStorage } from '../../helpers/SetLocalStorage';

const MAIN = 'Main';

const members = [
    {
        userLevel: 'MASTER',
        totalScore: 23,
        userName: 'Денис Гундорин',
    },
    {
        userLevel: 'MIDDLE',
        totalScore: 19,
        userName: 'Даниил Кориненко',
    },
    {
        userLevel: 'NOVICE',
        totalScore: 12,
        userName: 'Петр Петров',
    },
    {
        userLevel: 'NOVICE',
        totalScore: 8,
        userName: 'Аноним',
    },
    {
        userLevel: 'NOVICE',
        totalScore: 3,
        userName: 'Александр Поникаровский',
    },
];

const LandingWrapper = () => {
    const dispatch = useDispatch();
    const clientHeight = useClientHeight();
    const [showButton, setShowButton] = useState(0);
    const [authError, setAuthError] = useState(false);
    const sectionAuth = useRef(null);
    const loadQuestions = useSelector(state => state.questions.status === 'fulfilled')
    const attempts = useSelector(state => state.auth.teamLead.attempts);
    const sendAuth = useSelector(state => state.auth.teamLeadSend);
    const [authLoading, setAuthLoading] = useState(false);
    const navigate = useNavigate();

    const getAuthText = (type) => {
        return <>
            <PrivacyPolicy type={type} text={'Нажимая «Начать тест»'}/>
        </>
    }

    useEffect(() => {
        if (!sectionAuth.current) return;
        let {y, height} = sectionAuth.current.getBoundingClientRect();
        setShowButton(height + y)
    }, [clientHeight])

    const fetchSelfAuth = async (name, email) => {
        setAuthLoading(true);
        setAuthError(false);
        const body = {
            fullName: name,
            email,
            teamDevs: []
        }
        let fetchApi = FetchApi(`/signup/teamlead`)
        const auth = await fetchApi.post({body});
        if(auth.isError) {
            setAuthLoading(false);
            setAuthError(true);
        } else {
            setAuthLoading(false);
            dispatch(setAuth(auth));
            ApiLocalStorage('authData', auth);
            if (auth.attempts > 1) {
                return;
            } else {
                navigate('/test');
            }
        }
    }

    const scrollPageTo = () => {
        window.scrollTo(0, 0);
    }

    const fetchTeamInvite = async (name, email) => {
        const body = {
            fullName: name,
            email,
            teamDevs: []
        }
        const fetchApi = FetchApi('/invite_dev')
        const response = await fetchApi.post({body})
        if (response.isError) {
            console.log('alert');
        }
    }

    return (<>
        <Header />
        <div className={style.wrapper}>
            <section className={'asm-header'} ref={sectionAuth}>
                <div className={`asm-header_wrapper ${style.col} ${style.mainTitle}`}>
                    <div className={'asm-main-title'} style={{color: '#fff'}}>VK Security Champion</div>
                    <div className={`asm-lend-text`}>Оцените навыки безопасной разработки и узнайте, кто из ваших коллег — настоящий Security Champion.</div>
                </div>
                <AuthForm rule={{email: {required: true, email: true},name: {required: true}}}
                          handleSendTeam={fetchTeamInvite}
                          handleSendSelf={fetchSelfAuth}
                          setAuthError={setAuthError}
                          title={'Самому'}
                          type={MAIN}
                          attempts={attempts}
                          error={authError}
                          Style={{backgroundColor: 'rgba(253, 216, 53, 0.08)'}}
                >
                    {getAuthText(MAIN)}
                </AuthForm>
            </section>
            <div className={style.sectionHeader}>
                <div className={style.sectionHeaderTitle}>
                    Результаты и дашборд
                </div>
                <div className={style.sectionHeaderText}>После прохождения показываем личный результат и уровень подготовки. На дашборде можно увидеть результаты своих коллег. На пересдачу одна попытка, и пусть победит сильнейший!</div>
            </div>
            <div className={style.demoWrapper}>
                <Table members={members}></Table>
            </div>
        </div>
            <Footer/>
        </>
    );
};

export default LandingWrapper;
