const emailRegexp = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/i;

export const validRules = (rule, fields) => {
	let validate = fields.map(item => {
		const {name, value} = item;
		if (!rule[name]) return;
		if (!rule[name].required && !value) {
			return {
				name,
				valid: true,
				error: false
			};
		}

		if (rule[name].required && !value) {
			return {
				name,
				valid:false,
				error: true,
				errorType: 'required'
			};
		}

		if (rule[name].email) {
			let val = validRegExp(value, emailRegexp);
			if (!val) {
				return {
					name,
					valid: false,
					error: true,
					errorType: 'email'
				};
			}
		}

		return {
			name,
			valid: true,
			error: false
		};
	}).filter(item => item.error);
	if (!validate.length) return false;
	return validate.reduce((prevVal, item) => {
		if (item.valid) return prevVal;
		prevVal[item.name] = item;
		return prevVal;
	}, {});
};

const validRegExp = (value, pattern) => {
	return value.match(pattern);
};

