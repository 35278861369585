import React from 'react';
import style from '../../Dashboard/Dashboard.module.less';
import styleM from './Table.module.less';

const low = '#F44336';
const middle = '#FFA726';
const high = '#90CAF9';

export function Table({members, questionsNames}) {

	const getLevel = (level) => {
		return level === 'NOVICE' ?
			<><span className={style.levelIcon}>🔴</span> Новичок</> :
			level === 'MIDDLE' ?
				<><span className={style.levelIcon}>🟠</span> Миддл</> :
				<><span className={style.levelIcon}>💎</span> Чемпион</>
	}

	const getScore = (score) => {
		return `${score} ${declOfNum(score, ['балл', 'балла', 'баллов'])}`
	}

	const declOfNum = (number, words) => {
		return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
	}

	const getMobileTable = () => {
		return members.map((item, i) => <div key={i + 'm'} className={styleM.table}>
			<div className={styleM.number}>{i + 1}</div>
			<div className={styleM.block}>
				<div className={styleM.name}>{item.userName}</div>
				<div className={styleM.scoreBord}>
					<div className={styleM.score}>{getScore(item.totalScore)}</div>
					<div className={styleM.level}>{getLevel(item.userLevel)}</div>
				</div>
			</div>
		</div>)
	}

	const getMembers = () => {
		return members.map((item, i) => <div key={i} className={style.dashboardRow}>
			<div className={style.dashboardPth}>{i+1}</div>
			<div className={style.dashboardCell}>{item.userName}</div>
			<div className={style.dashboardScore}>{item.totalScore}</div>
			<div className={style.dashboardCell}>{getLevel(item.userLevel)}</div>
		</div>)
	}

	return (
		<>
			<div className={styleM.desktop}>
				<div className={style.dashboardRow}>
					<div className={`${style.dashboardPth} ${style.tableTitle}`}>Место</div>
					<div className={`${style.dashboardCell} ${style.tableTitle}`}>Участник</div>
					<div className={`${style.dashboardScoreHeader} ${style.tableTitle}`}>Итого баллов</div>
					<div className={`${style.dashboardCell} ${style.tableTitle}`}>Уровень</div>
				</div>
				{getMembers()}
			</div>
			<div className={styleM.mobile}>
				{getMobileTable()}
			</div>
		</>
);
}
