import React from 'react';
import style from './Button.module.less';

const PRIME = 'Prime';

const Button = ({btnStyle = PRIME, text, handleClick, disabled, size='lg'}) => {

  return (
    <button className={`${style.button} ${style['button'+btnStyle]}`}
            onClick={handleClick}
            disabled={disabled}
            data-size={size}
    >
        {text}
    </button>
  );
};

export default Button;
