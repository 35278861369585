import React, { useContext, useEffect, useState } from 'react';
import style from './Test.module.less';
import Question from './Question';
import Button from '../../UI/Button';
import { useNavigate } from 'react-router-dom';
import { ApiLocalStorage } from '../../helpers/SetLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import FetchApi from '../../helpers'
import { setResult } from '../../redux/questionsResult';
import { QUESTION_QUANTITY } from '../../config.test';

const Test = ({questions}) => {
    const [active, setActive] = useState(0);
    const [activeTopic, setActiveTopic] = useState(0);
    const [activeId, setActiveId] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [topicNames] = useState(Object.keys(questions));
    const developer = useSelector(state => state.auth.develop);
    const teamLead = useSelector(state => state.auth.teamLead);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        let prevAnswers = ApiLocalStorage('result');
        if (prevAnswers) {
            setAnswers(prevAnswers);
            setActiveTopic(Math.floor(prevAnswers.length / QUESTION_QUANTITY));
            setActive(prevAnswers.length % QUESTION_QUANTITY);
        }
    }, [])

    useEffect(() => {
        getActiveAnswerId(active)
    }, [active])

    const getActiveQuestion = () => {
        if (activeTopic === topicNames.length) {
            setActiveTopic(topicNames.length - 1);
            setActive(QUESTION_QUANTITY - 1);
            setBtnDisabled(false);
            return questions[topicNames[topicNames.length - 1]][active]
        }
        return questions[topicNames[activeTopic]][active]
    }

    const handleAnswers = async ({questionId, answerId, topic}) => {
        setActiveId(answerId);
        let arr = [];
        let count = active + QUESTION_QUANTITY*activeTopic
        if (answers.length > count) {
            arr = answers.map(item => {
                if (item.questionId === questionId) {
                    return {questionId, id: answerId, topic}
                } else {
                    return item;
                }
            })
        } else {
            arr = [...answers, {questionId, id: answerId, topic}];
        }
        ApiLocalStorage('result', arr);
        setAnswers(arr);
        setBtnDisabled(false);
    }

    const finalTest = async () => {
        const fetchApi = FetchApi('/test-result')
        const body = {
            userEmail: developer?.email ? developer?.email : teamLead?.email,
            answers: ApiLocalStorage('result')
        }
        let response = await fetchApi.put(null, {body});
        dispatch(setResult(response))
        localStorage.removeItem('result');
        localStorage.removeItem('questions');
        navigate('/result')
    }

    const nextQuestion = () => {
        let prevAnswers = ApiLocalStorage('result');
        if (active === QUESTION_QUANTITY - 1 && activeTopic < topicNames.length - 1) {
            setActive(0);
            setActiveTopic(activeTopic + 1)
            if (prevAnswers.length <= (QUESTION_QUANTITY*(activeTopic + 1))) {
                setBtnDisabled(true);
            }
            return;
        }
        if (active === QUESTION_QUANTITY - 1 && activeTopic === topicNames.length - 1) {
            finalTest();
            return;
        }
        setActive(() => {
            if (prevAnswers.length <= (QUESTION_QUANTITY*activeTopic + active+1)) {
                setBtnDisabled(true);
            }
            return active + 1;
        });
    }

    const previewQuestion = () => {
        if (active === 0) {
            setActiveTopic(activeTopic - 1);
            setActive(QUESTION_QUANTITY - 1);
            return;
        }
        setActive(active - 1);
    }

    const getActiveAnswerId = (active) => {
        let position = active + (QUESTION_QUANTITY*activeTopic);
        if (answers && answers[position] && answers[position].id) {
            setActiveId(answers[position].id);
        } else {
            setActiveId(null);
        }
    }

    return (
        <>
            <div className={style.wrapper}>
                <div className={style.questionTitle}>{active + 1}/{QUESTION_QUANTITY} {topicNames[activeTopic]}</div>
            </div>
            <div className={style.questionWrapper}>
                <Question key={active + activeTopic}
                          question={getActiveQuestion()}
                          handleAnswers={handleAnswers}
                          active={activeId}
                />
            </div>
            <div className={style.footer}>
                <div className={style.footerWrapper}>
                    <div className={style.footerRow}>
                        <div className={style.footerBtn}>
                            { (active > 0 || activeTopic > 0)  && <Button text={<svg className={`${style.arrow} ${style.arrowReverse} ${btnDisabled && style.arrowDisabled}`} width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.33331 10H23.6666M23.6666 10L15.6666 2M23.6666 10L15.6666 18" strokeWidth="3"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>}
                                    handleClick={previewQuestion}
                                    disabled={btnDisabled}
                                    btnStyle={'Opacity'}
                            />}
                        </div>
                        <div className={style.footerBtn}>
                            <Button text={active === QUESTION_QUANTITY - 1 && activeTopic === (topicNames.length -1) ? 'Узнать результат' : <svg width="26" className={`${ style.arrow } ${btnDisabled && style.arrowDisabled}`} height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.33331 10H23.6666M23.6666 10L15.6666 2M23.6666 10L15.6666 18" strokeWidth="3"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>}
                                    handleClick={nextQuestion}
                                    disabled={btnDisabled}
                                    btnStyle={'Main'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Test;
