import React, { useContext, useEffect } from 'react';
import style from './TestStart.module.less';
import DeveloperAuth from '../DeveloperAuth';
import Header from '../Header';
import Footer from '../Footer';
import Test from '../Test';
import { useSelector } from 'react-redux';
import { getTestQuestions } from '../../reselect/questions';
import { AuthContext } from '../../context';
import { useNavigate } from 'react-router-dom';

const TestStart = () => {
    const isAuth = useContext(AuthContext)
    const navigate = useNavigate()
    const topicName = useSelector(state => state.topic.name)
    const questions = useSelector(state => getTestQuestions(state, topicName))
    const status = useSelector(state => state.questions.status)

    useEffect(() => {
        if (!isAuth) {
            navigate('/');
        } else {
            navigate('/test');
        }
    }, [])

    return (
        <>
            <Header />
            {isAuth && status === 'fulfilled' && questions && <Test questions={questions} />}
        </>

    );
};

export default TestStart;
