import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../helpers/fetchApi';

const initialState = {
	status: 'idle',
	questions: [],
	error: false
};

export const fetchQuestions = createAsyncThunk(
    'questions/load',
    async () => {
		const fetchApi = FetchApi('/questions/new')
	    const questions = await fetchApi.get();
	    return questions;
    }
);

const questionSlice = createSlice({
	name: 'questions',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchQuestions.fulfilled]: (state, { payload }) => {
			state.status = 'fulfilled';
			state.error = false;
			state.questions = payload;
		},
		[fetchQuestions.pending]: (state) => {
			state.status = 'pending';
		},
		[fetchQuestions.rejected]: (state) => {
			state.status = 'rejected';
		},
	}
});

export default questionSlice.reducer;
