import React from 'react';
import style from './CallToAction.module.less';
import image from '../../images/Page.png';
import Link from '../../UI/Link';

const CallToAction = () => {
    return (
        <div className={style.asmCall}>
            <div className={style.asmCallTitle}>Start EDU научит разработчика писать красивый код без уязвимостей, не отвлекаясь от рабочих задач</div>
            <div className={style.asmCallLink}>Скоро Start EDU будет доступен для разработчиков VK</div>
            <img className={style.asmCallPage} src={image} alt="картинка"/>
        </div>
    );
};

export default CallToAction;
