import React, { useEffect, useState } from 'react';
import style from './AuthForm.module.less';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import {validRules} from '../../helpers';
import loaderImage from '../../images/loading.svg';
import reloadImage from '../../images/reload.svg';
import successImage from '../../images/ok.svg';
import { useNavigate } from 'react-router-dom';

const READY = 'ready';
const LOADING = 'loading';
const SUCCESS = 'success';
const RELOAD = 'reload';

const AuthForm = ({rule, handleSendSelf, children, error, setAuthError, attempts}) => {
    let navigate = useNavigate();
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [selfStatus, setSelfStatus] = useState(READY)
    const [errorForm, setErrorForm] = useState(false);

    useEffect(() => {
        setAuthError(false);
    }, [name, email]);

    const toDashboard = () => {
        navigate('/dashboard');
    }

    const sendSelf = () => {
        setAuthError(false);
        let validate = validRules(rule, [{name: 'name', value: name}, {name: 'email', value: email}]);
        if (validate) {
            setErrorForm(validate);
            setSelfStatus(READY);
            return;
        }
        setErrorForm(validate);
        setSelfStatus(LOADING);
        handleSendSelf(name, email);
        setSelfStatus(SUCCESS);
        setTimeout(() => {
            setSelfStatus(RELOAD);
        }, 3000);
    }

    return (
        <div className={`${style.wrapper} ${style.wrapperPrime} ${error && style.wrapperError}`}>
            {!(attempts > 1) ? <><div className={style.inputWrapper}>
                <Input value={name}
                       setValue={setName}
                       error={errorForm?.name}
                       placeholder={'Имя и фамилия'}
                />
            </div>
            <div className={style.inputWrapper}>
                <Input value={email}
                       setValue={setEmail}
                       error={errorForm?.email}
                       placeholder={'Рабочая почта'}
                />
            </div>
                {error && <div className={style.textError}>Проверьте правильность домена. Подойдет адрес, который заканчивается на .com, .net, .ru, .me, .org и .team</div>}
            <div className={style.buttonWrapper}>
                <Button text={selfStatus === LOADING ?
                    (<div className={style.iconWrapper}><img src={loaderImage} className={style.icon}/></div>) :
                    selfStatus === SUCCESS ?
                        (<div className={style.iconWrapper}><img src={successImage} className={style.icon}/></div>) :
                        selfStatus === RELOAD ?
                            (<div className={style.iconWrapper}><img src={reloadImage} className={style.icon}/></div>) : 'Начать тест'}
                        handleClick={sendSelf}
                        btnStyle={'Main'}
                />
            </div>
            {/*{(teamStatus === SUCCESS ||  teamStatus === RELOAD) &&*/}
            {/*<div className={style.text}>По адресу {email} отправлена ссылка на тест для вашей команды.</div>}*/}
            {/*{(selfStatus === SUCCESS || selfStatus === RELOAD) &&*/}
            {/*<div className={style.text}>По адресу {email} отправлена ссылка на персональный тест.</div>}*/}
            <div className={style.text}>
                {children}
            </div></> : <>
                <div className={`asm-lend-text ${style.inputWrapper}`}>Кажется, вы использовали все попытки пройти тест. Мы запомнили лучший результат!</div>
                <div className={`asm-lend-text ${style.inputWrapper}`}>Узнайте, на каком вы месте в общем рейтинге.</div>
                <div>
                    <Button text={'Открыть рейтинг'}
                            handleClick={toDashboard}
                            btnStyle={'Main'}
                    />
                </div>
            </>}
        </div>
    );
};

export default AuthForm;
