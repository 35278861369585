import { createBrowserRouter } from 'react-router-dom';
import LandingWrapper from './components/LandingWrapper';
import TestStart from './components/TestStart';
import ResultPage from './components/ResultPage';
import React from 'react';
import Dashboard from './components/Dashboard';
import OneAuth from './components/OneAuth';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <LandingWrapper />,
	},
	{
		path: '/test',
		element: <TestStart />
	},
	{
		path: '/result',
		element: <ResultPage />
	},
	{
		path: '/result/:id',
		element: <ResultPage />
	},
	// {
	// 	path: '/confirm_email/dev/*',
	// 	element: <TestStart />
	// },
	// {
	// 	path: '/confirm_email/*',
	// 	element: <TestStart />
	// },
	// {
	// 	path: '/test_one',
	// 	element: <OneAuth />
	// },
	{
		path: '*',
		element: <LandingWrapper/>
	},
	{
		path: '/dashboard',
		element: <Dashboard />
	}
]);
