import React, { useEffect, useState } from 'react';
import style from './Dashboard.module.less';
import Header from '../Header';
import { useSelector } from 'react-redux';
import { questionsName } from '../../reselect/questions';
import FetchApi from '../../helpers/fetchApi';
import { Table } from '../DashboardTable/Table/Table';

const low = '#F44336';
const middle = '#FFA726';
const high = '#90CAF9';

const Dashboard = () => {
    const [members, setMembers] = useState([])
    const questionsNames = useSelector(questionsName)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const fetchApi = FetchApi('/test-result/dashboard');
        const data = await fetchApi.get();
        if (!data.isError) {
            setMembers(data);
        }
        setTimeout(() => {
            loadData()
        }, 10000)
    }

    return (
        <>
            <Header dashboard={true}/>
            <div className={style.wrapper}>
                <section className={style.header}>
                    <div className={style.headerText}>
                        <span className={style.headerDescription}>VK Security Champion</span>
                        <span className={style.headerTitle}>Рейтинг участников</span>
                    </div>
                </section>
                <Table members={members} questionsNames={questionsNames}/>
            </div>
        </>
    );
};

export default Dashboard;
