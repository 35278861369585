import React from 'react';
import style from './Header.module.less';
import Link from '../../UI/Link';
import logo from '../../images/logo.svg';
import { Link as LinkRouter, useLocation } from "react-router-dom";

const Header = ({dashboard}) => {
const location = useLocation();

  return (
    <header className={style.headerWrapper}>
        <div className={style.container}>
            <div className={style.row}>
                <span className={style.logo}>
                    <LinkRouter to={location.pathname === '/test' ? null : '/'}
                    ><img className={style.logoImg} src={logo}/></LinkRouter>
                </span>
                {location.pathname !== '/dashboard' && location.pathname !== '/test' && <div><LinkRouter to={'/dashboard'} className={style.linkDashboard}>Рейтинг участников</LinkRouter></div>}
                {/*<div className={style.link}>*/}
                {/*    <Link address={'mailto:devsecops@startx.team'}*/}
                {/*          linkClass={'Default'}*/}
                {/*          type={'_blank'}*/}
                {/*    >devsecops@startx.team</Link>*/}
                {/*</div>*/}
            </div>
        </div>
    </header>
  );
};

export default Header;
