import React, { useContext, useEffect, useState } from 'react';
import style from './ResultPage.module.less';
import Header from '../Header';
import Footer from '../Footer';
import Button from '../../UI/Button';
import CallToAction from '../CallToAction';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context';
import { ResultVk } from '../ResultVK';

const ResultPage = () => {
    let navigate = useNavigate();
    const testResult = useSelector(state => state.questionsResult.result);
    const [place, setPlace] = useState(null);
    const [attempts, setAttempts] = useState(0);
    const [totalScore, setTotalScore] = useState(0);
    const isAuth = useContext(AuthContext)

    useEffect(() => {
        if (!isAuth || !testResult) {
            navigate('/');
            return;
        }
        setAttempts(testResult.attempts);
        setPlace(testResult.place);
        setTotalScore(testResult.totalScore);
    }, [])

    const replayTest = () => {
        navigate('/test')
    }

    return (
        <>
            <Header />
            <div className={style.wrapper}>
                <div className={style.row}>
                    <div className={`asm-lend-text ${style.col}`} style={{textAlign: 'center', fontSize: '40px'}}>Поздравляем! Вы сдали тест «Security Champion»</div>
                    <div className={`${style.col} ${style.rowWrapper}`} style={{marginTop: '24px'}}>
                        <ResultVk place={place} totalScore={totalScore}></ResultVk>
                    </div>
                    {!(attempts > 1) && <div className={style.buttonWrapper}>
                        <div>Если недовольны результатом, пересдайте тест. У вас будет одна попытка</div>
                        <div className={style.button}>
                            <Button text={<div className={style.buttonContext}>Пересдать</div>}
                                    btnStyle={'Opacity'}
                                    handleClick={replayTest}
                                    size={'md'}
                            />
                        </div>
                    </div>}
                    <div><CallToAction /></div>
                </div>
            </div>
            <Footer />
        </>
)};

export default ResultPage;
