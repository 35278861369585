import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../helpers';
import { ApiLocalStorage } from '../helpers/SetLocalStorage';

const initialState = {
	status: 'idle',
	teamLead: {},
	develop: {},
	devSend: false,
	teamLeadSend: false,
};

export const signupDev = createAsyncThunk(
	'auth/signupDev',
	async (params) => {
		let fetchApi = FetchApi(`/signup/dev`)
		const auth = await fetchApi.post({body:params});
		if (auth.isError) {
			return params;
		}
		return auth;
	}
);

export const signupTeamLead = createAsyncThunk(
	'auth/signupTeamLead',
	async (params) => {
		let fetchApi = FetchApi(`/signup/teamlead`)
		const auth = await fetchApi.post({body:params});
		if (auth.isError) {
			throw auth;
		}
		return auth;
	}
);

export const fetchTlAuth = createAsyncThunk(
	'auth/teamLead',
	async (params) => {
		const {location} = window;
		let fetchApi = FetchApi(`${location.pathname}${location.search}`)
		const auth = await fetchApi.get();
		return auth;
	}
);

export const fetchDvAuth = createAsyncThunk(
	'auth/developer',
	async (params) => {
		const {location} = window;
		let fetchApi = FetchApi(`${location.pathname}${location.search}`)
		const auth = await fetchApi.get();
		return auth;
	}
);

export const fetchDEVAuth = createAsyncThunk(
	'auth/fetchDev',
	async (params) => {
		const {location} = window;
		let fetchApi = FetchApi(`${location.pathname}${location.search}`)
		const auth = await fetchApi.get();
		return auth;
	}
);

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuth (state, {payload}) {
			state.status = true;
			state.teamLead = payload;
		},
		setSend (state) {
			state.teamLeadSend = false;
		}
	},
	extraReducers: {
		[fetchTlAuth.fulfilled]: (state, { payload }) => {
			ApiLocalStorage('authData', payload);
			state.status = 'load';
			state.teamLead = payload;
		},
		[fetchDvAuth.fulfilled]: (state, { payload }) => {
			state.status = false;
			ApiLocalStorage('TL_data', payload);
			state.teamLead = payload;
		},
		[signupDev.fulfilled]: (state, { payload }) => {
			state.devSend = true;
			state.status = true;
			ApiLocalStorage('authData', payload);
			state.teamLead = payload;
		},
		[signupTeamLead.fulfilled]: (state, { payload }) => {
			state.teamLeadSend = true;
			ApiLocalStorage('authData', payload);
			state.status = 'load';
			state.teamLead = payload;
		},
		[signupTeamLead.rejected]: (state) => {
			state.teamLeadSend = false;
			state.status = 'error';
		},
		[fetchDEVAuth.fulfilled]: (state, { payload }) => {
			ApiLocalStorage('authData', payload);
			state.status = true;
			state.develop = payload;
		},
	}
});

export const {setAuth, setSend} = authSlice.actions;
export default authSlice.reducer;
