import React from 'react';
import style from './ResultVK.module.less';
import {Link} from 'react-router-dom';

export function ResultVk({totalScore, place}) {

	const getLevel = () => {
		return totalScore < 14 ?
			<><span>🔴</span> Новичок</> :
			totalScore < 20 ?
				<><span>🟠</span> Миддл</> :
				<><span>💎</span> Чемпион</>
	}

	return (
		<div className={style.wrapper}>
			<div className={style.score}>
				<div className={style.title}>Результат</div>
				<div className={style.text}>
					<span>{totalScore}</span>
					<span className={style.greyText}>/27</span>
				</div>
			</div>
			<div className={style.score}>
				<div className={style.title}>Уровень</div>
				<div className={style.text}>{getLevel()}</div>
			</div>
			<div className={style.score}>
				<div className={style.title}>Место <Link to={'/dashboard'} className={style.link}>в рейтинге</Link></div>
				<div className={style.text}>{place}</div>
			</div>
		</div>
	);
}
