import { createSelector } from 'reselect';
import { ApiLocalStorage } from '../helpers/SetLocalStorage';

const questions = (state, topicName) => ({questions: shuffle(getNotExtensibleData(state.questions.questions)), name: topicName});

const shuffle = (array) => {
	let newArray = [...array];
	for (let i = newArray.length - 1; i > 0; i--) {
		let j = Math.floor(Math.random() * (i + 1));
		[newArray[i], newArray[j]] = [newArray[j], newArray[i]];
	}
	return newArray;
}

const getNotExtensibleData = (data) => {
	return data.map((item) => {
		let newObj = {};
		for (let key in item) {
			newObj[key] = item[key];
		}
		return newObj;
	});
};

export const questionsName = createSelector(questions, ({questions}) => {
	let names = [];
	let arr = [];
	questions.forEach(item => {
		if (names.indexOf(item.topic) === -1) {
			names.push(item.topic)
			arr.push({icon: item.icon, text: item.topic})
		}
	});
	return arr;
});

export const getTestQuestions = createSelector(questions, ({questions, name})  => {
	if (ApiLocalStorage('questions')) {
		if (name) {
			return {[name]:ApiLocalStorage('questions')[name]};
		}
		return ApiLocalStorage('questions');
	}
	let topic = {};
	let returnedValue = {};
	if (name) {
		questions = questions.filter(item => item.topic === name);
	}
	const items = getNotExtensibleData(questions);
	items.forEach(item => {
		if (!topic[item.topic]) {
			topic[item.topic] = [item]
		} else {
			topic[item.topic].push(item);
		}
	});
	for (let key in topic) {
		shuffle(topic[key])
		let i = [];
		topic[key].forEach((item, index) => {
			if (returnedValue?.[key]?.[index]?.id === item.id) {
				i.push(item.score)
			} else if (~i.indexOf(item.score)) {
				item.remove = true;
			} else {
				i.push(item.score)
			}
		});
		returnedValue[key] = topic[key].filter(item => !item.remove);
		returnedValue[key].forEach(item => {
			item.answers = shuffle(item.answers);
		})
	}
	ApiLocalStorage('questions', returnedValue);
	return returnedValue;
});
